import React, { useState, useEffect } from "react";
import {
	ButtonToolbar,
	Button,
	Form,
	Modal,
	Container,
	Row,
	Col,
} from "react-bootstrap";
import axios from "../../constants/axiosRequest";
import { createLog } from "../../utils/apiCall";
import logger from "../../utils/logger";
import { connect } from "react-redux";

const CopyModal = ({ data, apiUrl, user, ...rest }) => {
	const [modalShow, setModalShow] = useState(false);
	const [formData, setFormData] = useState({
		title: data.title,
		description: data.description,
		documentType: data.documentType,
		instrumentId: data.INSTRUMENTID,
		testId: data.TESTID,
		visibility: data.visibility,
	});
	const [updateState, setUpdateState] = useState(true);

	const userName = `${user.FirstName} ${user.LastName}`;

	useEffect(() => {
		const fetchData = async () => {
			setFormData({
				title: data.title,
				description: data.description,
				documentType: data.documentType,
				instrumentId: data.INSTRUMENTID,
				testId: data.TESTID,
				visibility: data.visibility,
			});
		};

		fetchData();
	}, [updateState, data]);

	const {
		title,
		description,
		documentType,
		instrumentId,
		testId,
		visibility,
	} = formData;

	const onClick = () => {
		setUpdateState(!updateState);
		setModalShow(true);
	};

	const onChange = (e) =>
		setFormData({ ...formData, [e.target.name]: e.target.value });

	const onSubmit = async (e) => {
		e.preventDefault();
		setModalShow(false);

		try {
			const experiment = await axios.post(apiUrl, {
				title,
				description,
				documentType,
				instrumentId,
				testId,
				userName,
				visibility,
			});

			if (experiment !== undefined) {
				await createLog(
					"Create Experiment",
					`${user.FirstName} ${user.LastName} created experiment ${title}`
				);
			}

			rest.updateState();
		} catch (err) {
			logger("error", err);
		}
	};

	return (
		<ButtonToolbar>
			<Button
				variant="primary"
				className="modal-button btn-sm ml-3"
				onClick={() => onClick()}
			>
				<i className="fas fa-clone" /> Copy
			</Button>
			<Modal
				show={modalShow}
				onHide={() => setModalShow(false)}
				size="md"
				aria-labelledby="form-modal"
				centered
			>
				<Modal.Header closeButton>
					<Modal.Title id="form-modal">
						<i className="fas fa-clone" /> {"Copy " + data.title}
					</Modal.Title>
				</Modal.Header>
				<Modal.Body>
					<Form onSubmit={(e) => onSubmit(e)}>
						<Form.Group controlId="formTitle">
							<Form.Label>Title</Form.Label>
							<Form.Control
								required
								type="text"
								name="title"
								value={formData.title}
								placeholder="Enter Title"
								onChange={(e) => onChange(e)}
							/>
						</Form.Group>
						<Form.Group controlId="formDescription">
							<Form.Label>Description</Form.Label>
							<Form.Control
								as="textarea"
								name="description"
								value={formData.description}
								placehoder="Enter Description"
								onChange={(e) => onChange(e)}
							/>
						</Form.Group>
						<Form.Group controlId="formVisibility">
							<Form.Label>Visibility</Form.Label>
							<Form.Control
								as="select"
								name="visibility"
								value={formData.visibility}
								onChange={(e) => onChange(e)}
							>
								<option value="Public">Public</option>
								<option value="Private">Private</option>
							</Form.Control>
						</Form.Group>
						<Container>
							<Row>
								<Col>
									<Button variant="primary" type="submit">
										Copy
									</Button>
								</Col>
								<Col>
									<Button onClick={() => setModalShow(false)}>
										Close
									</Button>
								</Col>
							</Row>
						</Container>
					</Form>
				</Modal.Body>
			</Modal>
		</ButtonToolbar>
	);
};

const mapStateToProps = (state) => ({
	user: state.auth.user,
});

export default connect(mapStateToProps)(CopyModal);

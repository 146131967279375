import React, { useState } from "react";
import { connect } from "react-redux";
import { Redirect } from "react-router-dom";
import { Form, Button, Container } from "react-bootstrap";
import LoadingModal from "../layout/LoadingModal";
import { setAlert } from "../../actions/alert";
import { resetPassword } from "../../utils/apiCall";
import placeholder from "../../img/Lablynx-logo.png";

const PasswordReset = ({ setAlert, logo, location, history }) => {
	const [loadingModalShow, setLoadingModalShow] = useState(false);
	const [formData, setFormData] = useState({
		password: "",
		confirmPassword: "",
	});

	const searchParams = new URLSearchParams(location.search);
	const token = searchParams.get("token");

	if (!token) {
		return <Redirect to="/" />;
	}

	const onChange = (e) => {
		e.preventDefault();
		setFormData({ ...formData, [e.target.name]: e.target.value });
	};

	const onSubmit = async (e) => {
		e.preventDefault();
		setLoadingModalShow(true);

		const password = formData.password;
		const confirmPassword = formData.confirmPassword;

		try {
			if (password !== confirmPassword) {
				throw Error("Password confirmation does not match password");
			}

			const updatedUser = await resetPassword(
				formData.password,
				formData.confirmPassword,
				token
			);
			console.log(updatedUser);

			if (updatedUser) {
				setAlert("Password Updated", "success", 1500);
				history.push("/");
			}
		} catch (err) {
			setAlert(err, "danger", 1500);
		} finally {
			setLoadingModalShow(false);
		}
	};

	return (
		<div className="container-fluid">
			<LoadingModal modalShow={loadingModalShow} />
			<div className="row justify-content-center logo">
				<div className="col- shadow bg-white rounded panel text-center">
					<span>
						<img
							src={
								logo
									? `data:image/jpeg;base64, ${logo}`
									: placeholder
							}
							alt="Client Logo"
							className="img-fluid"
						/>
					</span>
					<Form onSubmit={(e) => onSubmit(e)}>
						<Form.Group controlId="formPassword">
							<Form.Label>New Password</Form.Label>
							<Form.Control
								required
								type="password"
								name="password"
								placeholder="Enter new password"
								value={formData.password}
								onChange={(e) => onChange(e)}
							/>
						</Form.Group>
						<Form.Group controlId="formConfirmPassword">
							<Form.Label>Confirm New Password</Form.Label>
							<Form.Control
								required
								type="password"
								name="confirmPassword"
								placeholder="Confirm new password"
								value={formData.confirmPassword}
								onChange={(e) => onChange(e)}
							/>
						</Form.Group>
						<Container>
							<Button variant="primary" type="submit">
								{"Submit"}
							</Button>
						</Container>
					</Form>
				</div>
			</div>
		</div>
	);
};

const mapStateToProps = (state) => ({
	logo: state.config.logo,
});

export default connect(mapStateToProps, { setAlert })(PasswordReset);

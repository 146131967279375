import React, { useState, useEffect, Fragment } from "react";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import TemplatesCard from "../layout/TemplatesCard";
import TemplatesModal from "../layout/TemplatesModal";
import FileUploadModal from "../layout/FileUploadModal";
import SearchField from "../layout/SearchField";
import SideBar from "../layout/SideBar";
import Pagination from "../layout/Pagination";
import paginate from "../../utils/paginate";
import { getProjects, getTemplates, uploadFiles } from "../../utils/apiCall";

const Template = ({ user }) => {
  const [templates, setTemplates] = useState([]);
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [updateState, setUpdateState] = useState(false);
  const [page, setPage] = useState(0);
  const [projects, setProjects] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      const templates = await getTemplates();
      const pages = paginate(templates.reverse());
      const projects = await getProjects();

      setProjects(projects)
      setTemplates(pages);
      setData(templates);
      setLoading(false);
    };
    fetchData();
  }, [updateState]);

  const handlePage = (page) => {
    setPage(page);
  };

  const handleSearch = async (queryParam, query) => {
    const res = data.filter((template) => template[queryParam].toLowerCase().includes(query.toLowerCase()));
    const pages = paginate(res);
    setTemplates(pages);
  };

  const spinner = (
    <div className="d-flex justify-content-center">
      <div class="spinner-border m-5" role="status">
        <span class="sr-only">Loading...</span>
      </div>
    </div>
  );

  return (
    <Fragment>
      {loading && spinner}
      {!loading && (
        <div className="container-fluid white-background">
          <div className="row">
            <div className="col-lg-2 hidden-md side-bar-wrapper">
              <div className="side-bar">
                <SideBar projectList={projects} />
              </div>
            </div>
            <div className="col-12 col-lg-10 workspace">
              <div className="container-fluid">
                <nav className="navbar navbar-expand-xl navbar-light">
                  <h3>
                    <i className="fas fa-copy" /> Templates
                  </h3>
                  <button
                    className="navbar-toggler"
                    type="button"
                    data-toggle="collapse"
                    data-target="#navbarSupportedContent"
                    aria-controls="navbarSupportedContent"
                    aria-expanded="false"
                    aria-label="Toggle navigation"
                  >
                    <span className="navbar-toggler-icon"></span>
                  </button>
                  <div className="collapse navbar-collapse" id="navbarSupportedContent">
                    <ul className="navbar-nav mr-auto ml-auto">
                      <li className="nav-item mr-3">
                        <TemplatesModal edit={false} updateState={() => setUpdateState(!updateState)} />
                      </li>
                      <li className="nav-item">
                        <FileUploadModal
                          updateState={() => setUpdateState(!updateState)}
                          logFlag={1}
                          uploadFiles={async (data, userName) =>
                            await uploadFiles(
                              `${window._env_.REACT_APP_NODE_SERVER_HOSTNAME}:${window._env_.REACT_APP_NODE_SERVER_PORT}/api/templates/upload?userName=` +
                                userName,
                              data
                            )
                          }
                        />
                      </li>
                    </ul>
                    <ul className="navbar-nav">
                      <li className="nav-item mr-3">
                        <Link to="/projects" className="link">
                          <i className="fas fa-project-diagram" /> Projects
                        </Link>
                      </li>
                      <li className="nav-item mr-3">
                        <Link to="/search" className="link">
                          <i className="fas fa-search" /> Search
                        </Link>
                      </li>
                      <li className="nav-item mr-4">
                        <Link to="/logs" className="link">
                          <i className="fas fa-list" /> Activity Log
                        </Link>
                      </li>
                      <li className="nav-item">
                        <SearchField
                          useFilter={false}
                          handleSearch={(queryParam, query) => handleSearch(queryParam, query)}
                        />
                      </li>
                    </ul>
                  </div>
                </nav>
                <div className="row">
                  {templates.length > 0 ? (
                    templates[page].map((template) => (
                      <TemplatesCard
                        user={user}
                        edit={true}
                        template={template}
                        templateId={template.id}
                        updateState={() => setUpdateState(!updateState)}
                      />
                    ))
                  ) : (
                    <h1> No Templates Found...</h1>
                  )}
                </div>
                {templates.length > 1 && <Pagination data={templates} handlePage={(page) => handlePage(page)} />}
              </div>
            </div>
          </div>
        </div>
      )}
    </Fragment>
  );
};

const mapStateToProps = state => ({
  user: state.auth.user
});

export default connect(mapStateToProps)(Template);

import React, { useState, Fragment } from "react";
import {
	ButtonToolbar,
	ButtonGroup,
	Button,
	Form,
	Modal,
	Container,
	Row,
	Col,
} from "react-bootstrap";
import { connect } from "react-redux";
import { createTemplate, editTemplate, createLog } from "../../utils/apiCall";
import LoadingModal from "./LoadingModal";
import { setAlert } from "../../actions/alert";
import word from "../../img/Microsoft_Word_logo.png";
import excel from "../../img/288px-Microsoft_Excel_Logo.svg.png";

const TemplatesModal = ({ user, setAlert, ...props }) => {
	const [loadingModalShow, setLoadingModalShow] = useState(false);
	const [modalShow, setModalShow] = useState(false);
	const [formData, setFormData] = useState({
		title: props.template ? props.template.title : "",
		description: props.template ? props.template.description : "",
		documentType: props.template ? props.template.documentType : "docx",
	});
	const userName = `${user.FirstName} ${user.Lastname}`;

	const { title, description, documentType } = formData;
	const templateId = props.templateId;
	let buttonIcon = "plus";
	if (props.edit) {
		buttonIcon = "edit";
	}

	const onClick = () => {
		setFormData({
			title: props.template ? props.template.title : "",
			description: props.template ? props.template.description : "",
			documentType: props.template ? props.template.documentType : "docx",
		});
		setModalShow(true);
	};

	const onChange = (e) =>
		setFormData({ ...formData, [e.target.name]: e.target.value });

	const onSubmit = async (e) => {
		e.preventDefault();

		try {
			setModalShow(false);
			setLoadingModalShow(true);

			if (props.edit) {
				const template = await editTemplate(
					title,
					description,
					templateId,
					userName
				);

				if (template !== undefined) {
					await createLog(
						"Edit Template",
						`${user.FirstName} ${user.LastName} edited template ${title}`
					);
				}
			} else {
				const newTemplate = await createTemplate(
					title,
					description,
					documentType,
					userName
				);

				if (newTemplate !== undefined) {
					await createLog(
						"Create Template",
						`${user.FirstName} ${user.LastName} created template ${title}`
					);
				}
			}

			props.updateState();
			setAlert("Successfully Saved", "success", 1500);
		} catch (err) {
			setAlert("Error Saving Changes", "danger", 1500);
		} finally {
			setLoadingModalShow(false);
		}
	};

	return (
		<ButtonToolbar>
			<LoadingModal modalShow={loadingModalShow} />
			<Button
				variant="primary"
				className="modal-button btn-sm"
				onClick={() => onClick()}
			>
				<i className={`fas fa-${buttonIcon}`} />{" "}
				{props.edit ? "Edit" : "New Template"}
			</Button>
			<Modal
				show={modalShow}
				onHide={() => setModalShow(false)}
				size="md"
				aria-labelledby="form-modal"
				centered
			>
				<Modal.Header closeButton>
					<Modal.Title id="form-modal">
						<i className={`fas fa-${buttonIcon}`} />{" "}
						{props.edit ? "Edit Template" : "New Template"}
					</Modal.Title>
				</Modal.Header>
				<Modal.Body>
					<Form onSubmit={(e) => onSubmit(e)}>
						<Form.Group controlId="formTitle">
							<Form.Label>Title</Form.Label>
							<Form.Control
								required
								type="text"
								name="title"
								value={formData.title}
								placeholder="Enter Title"
								onChange={(e) => onChange(e)}
							/>
						</Form.Group>

						<Form.Group controlId="formDescription">
							<Form.Label>Description</Form.Label>
							<Form.Control
								as="textarea"
								name="description"
								value={formData.description}
								placehoder="Enter Description"
								onChange={(e) => onChange(e)}
							/>
						</Form.Group>
						{!props.edit && (
							<Fragment>
								<Form.Group controlId="formDocumentTypeButton">
									<ButtonGroup aria-label="Document Type">
										<Button
											className="document-button"
											variant="secondary"
											onClick={() =>
												setFormData({
													...formData,
													documentType: "docx",
												})
											}
										>
											<span>Word</span>
											<br />
											<img
												className="icon"
												src={word}
												alt="icon"
											/>
										</Button>
										<Button
											className="document-button"
											variant="secondary"
											onClick={() =>
												setFormData({
													...formData,
													documentType: "xlsx",
												})
											}
										>
											<span>Excel</span>
											<br />
											<img
												className="icon"
												src={excel}
												alt="icon"
											/>
										</Button>
									</ButtonGroup>
								</Form.Group>
							</Fragment>
						)}
						<Container>
							<Row>
								<Col>
									<Button variant="primary" type="submit">
										{props.edit ? "Edit" : "Create"}
									</Button>
								</Col>
								<Col>
									<Button onClick={() => setModalShow(false)}>
										Close
									</Button>
								</Col>
							</Row>
						</Container>
					</Form>
				</Modal.Body>
			</Modal>
		</ButtonToolbar>
	);
};

const mapStateToProps = (state) => ({
	user: state.auth.user,
});

export default connect(mapStateToProps, { setAlert })(TemplatesModal);

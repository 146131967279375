import React, { useState, useEffect } from "react";
import {
	ButtonToolbar,
	Modal,
	Button,
	Form,
	Container,
	Row,
	Col,
} from "react-bootstrap";
import { connect } from "react-redux";
import { setAlert } from "../../actions/alert";
import LoadingModal from "./LoadingModal";
import { createProject, createLog, editProject } from "../../utils/apiCall";

const ProjectsModal = ({ user, setAlert, ...props }) => {
	const [modalShow, setModalShow] = useState(false);
	const [formData, setFormData] = useState({});
	const [loadingModalShow, setLoadingModalShow] = useState(false);
	const [updateState, setUpdateState] = useState(true);
	const { title, projectTypeId, externalId } = formData;
	const userName = `${user.FirstName} ${user.LastName}`;

	useEffect(() => {
		const fetchData = async () => {
			setFormData({
				title: props.title ? props.title : "",
				projectTypeId: props.projectTypes
					? props.projectTypes[0].projectTypeId
					: -1,
				externalId: props.externalId ? props.externalId : -2,
			});
		};

		fetchData();
	}, [updateState, props.title, props.projectTypes, props.externalId]);

	let buttonIcon = "plus";
	if (props.edit) {
		buttonIcon = "edit";
	}

	const onClick = () => {
		setUpdateState(!updateState);
		setModalShow(true);
	};

	const onChange = (e) =>
		setFormData({ ...formData, [e.target.name]: e.target.value });

	const onSubmit = async (e) => {
		e.preventDefault();

		try {
			setModalShow(false);
			setLoadingModalShow(true);

			if (props.edit) {
				const projectData = {
					title,
					userName,
				};
				const project = await editProject(projectData, props.projectId);

				if (project !== undefined) {
					await createLog(
						"Edit Project",
						`${user.FirstName} ${user.LastName} edited project ${title}`
					);
				}
			} else {
				const project = await createProject(
					title,
					projectTypeId,
					externalId,
					userName
				);

				if (project !== undefined) {
					await createLog(
						"Create Project",
						`${user.FirstName} ${user.LastName} created project ${title}`
					);
				}
			}

			props.updateState();

			await setAlert("Successfully Saved", "success", 1500);
		} catch (err) {
			await setAlert("Error Saving Changes", "danger", 1500);
		} finally {
			setLoadingModalShow(false);
		}
	};

	return (
		<ButtonToolbar>
			<LoadingModal modalShow={loadingModalShow} />
			<Button
				variant="primary"
				className="modal-button btn-sm"
				onClick={(e) => onClick(e)}
			>
				<i className={`fas fa-${buttonIcon}`} />
				{props.edit ? "Edit" : <span> New Project</span>}
			</Button>
			<Modal
				show={modalShow}
				onHide={() => setModalShow(false)}
				size="md"
				aria-labelledby="form-modal"
				centered
			>
				<Modal.Header closeButton>
					<Modal.Title id="form-modal">
						<i className={`fas fa-${buttonIcon}`} />{" "}
						{props.edit ? "Edit Project" : "New Project"}
					</Modal.Title>
				</Modal.Header>
				<Modal.Body>
					<Form onSubmit={(e) => onSubmit(e)}>
						<Form.Group controlId="formTitle">
							<Form.Label>Title</Form.Label>
							<Form.Control
								required
								type="text"
								name="title"
								placeholder="Enter Title"
								value={formData.title}
								onChange={(e) => onChange(e)}
							/>
						</Form.Group>
						{!props.edit && (
							<Form.Group controlId="formType">
								<Form.Label>Type</Form.Label>
								<Form.Control
									as="select"
									name="projectTypeId"
									value={formData.projectTypeId}
									onChange={(e) => onChange(e)}
								>
									{Object.values(props.projectTypes).map(
										(projectType) =>
											projectType.active ? (
												<option
													value={
														projectType.projectTypeId
													}
												>
													{projectType.typeName}
												</option>
											) : (
												""
											)
									)}
								</Form.Control>
							</Form.Group>
						)}
						{formData.type === "Instrument" && !props.edit && (
							<Form.Group controlId="formTypeId">
								<Form.Label>Instrument</Form.Label>
								<Form.Control
									as="select"
									name="externalId"
									value={formData.externalId}
									onChange={(e) => onChange(e)}
								>
									<option value={-2}>N/A</option>
									{props.instruments
										? props.instruments.map(
												(instrument) => (
													<option
														value={
															instrument.INSTRUMENTID
														}
													>
														{
															instrument.INSTRUMENTNAME
														}
													</option>
												)
										  )
										: ""}
								</Form.Control>
							</Form.Group>
						)}
						<Container>
							<Row>
								<Col>
									<Button variant="primary" type="submit">
										{props.edit ? "Edit" : "Create"}
									</Button>
								</Col>
								<Col>
									<Button onClick={() => setModalShow(false)}>
										Close
									</Button>
								</Col>
							</Row>
						</Container>
					</Form>
				</Modal.Body>
			</Modal>
		</ButtonToolbar>
	);
};

const mapStateToProps = (state) => ({
	user: state.auth.user,
});

export default connect(mapStateToProps, { setAlert })(ProjectsModal);

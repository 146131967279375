import React, { Fragment, useEffect, useState } from "react";
import { Provider } from "react-redux";
import store from "./store";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import PrivateRoute from "./components/routing/PrivateRoute";
import Navbar from "./components/layout/Navbar";
import Landing from "./components/workspace/Landing";
import Login from "./components/auth/Login";
import Alert from "./components/layout/Alert";
import Spinner from "./components/layout/Spinner";
import LimsLogin from "./components/workspace/LimsLogin";
import AdminPanel from "./components/workspace/AdminPanel";
import OpensocialAdmin from "./components/workspace/OpensocialAdmin";
import ErrorBoundary from "./components/error/ErrorBoundary";
import PasswordReset from "./components/workspace/PasswordReset";
import Layout from "./Layout";
import { loadUser } from "./actions/auth";
import { getConfiguration, getFileConfiguration } from "./actions/config";
import { loadProjectTypes } from "./actions/projectType";
import setAuthToken from "./utils/setAuthToken";
import "./App.css";

if (localStorage.token) {
	setAuthToken(localStorage.token);
}

const App = () => {
	const [loading, setLoading] = useState(true);

	useEffect(() => {
		const fetch = async () => {
			await store.dispatch(loadUser());
			await store.dispatch(getConfiguration("clientName"));
			await store.dispatch(getConfiguration("loginText"));
			await store.dispatch(getConfiguration("displayLoginText"));
			await store.dispatch(getConfiguration("useRoles"));
			await store.dispatch(getConfiguration("useOpenSocial"));
			await store.dispatch(getConfiguration("limsModule"));
			await store.dispatch(getFileConfiguration("logo"));
			await store.dispatch(loadProjectTypes());
			setLoading(false);
		};
		fetch();
	}, []);

	return (
		<Provider store={store}>
			<Router>
				{loading && <Spinner />}
				{!loading && (
					<Fragment>
						<Navbar />
						<ErrorBoundary>
							<section>
								<Alert />
								<Switch>
									<Route exact path="/" component={Landing} />
									<Route exact path="/limslogin" component={LimsLogin} />
									<Route exact path="/login" component={Login} />
									<Route exact path="/passwordReset" component={PasswordReset} />
									<PrivateRoute exact path="/admin" component={AdminPanel} />
									<PrivateRoute exact path="/opensocial" component={OpensocialAdmin} />
									<Route path="/" component={Layout} />
								</Switch>
							</section>
						</ErrorBoundary>
					</Fragment>
				)}
			</Router>
		</Provider>
	);
};

export default App;

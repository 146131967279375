import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { Button, Form, Container, Row, Col } from "react-bootstrap";
import ModalButton from "../../../layout/ModalButton";
import LoadingModal from "../../../layout/LoadingModal";
import Spinner from "../../../layout/Spinner";
import ClickableCard from "../../../layout/ClickableCard";
import AssignUnassignTable from "../../../layout/AssignUnassignTable";
import { setAlert } from "../../../../actions/alert";
import {
	getAllUsers,
	updateUser,
	deleteUser,
	getRoles,
	createUser,
	setUserRoles,
	importLimsUsers,
} from "../../../../utils/apiCall";

const Users = ({ setAlert, loading, roleActive }) => {
	const [formData, setFormData] = useState({
		username: "",
		password: "",
		confirmPassword: "",
		firstName: "",
		lastName: "",
		email: "",
	});
	const [users, setUsers] = useState([]);
	const [roles, setRoles] = useState([]);
	const [modalShow, setModalShow] = useState(false);
	const [loadingModalShow, setLoadingModalShow] = useState(false);

	useEffect(() => {
		const fetchData = async () => {
			setUsers(await getAllUsers());
			setRoles(await getRoles());
		};

		fetchData();
	}, []);

	const onChange = (e) => {
		e.preventDefault();
		setFormData({ ...formData, [e.target.name]: e.target.value });
	};

	const onSubmit = async (e) => {
		e.preventDefault();

		try {
			setLoadingModalShow(true);

			if (formData.password !== formData.confirmPassword) {
				throw Error("Password confirmation does not match password");
			}

			setModalShow(false);

			await createUser(formData);
			setUsers(await getAllUsers());
			setAlert("Successfully Saved", "success", 1500);
		} catch (err) {
			setAlert("Error Saving Changes", "danger", 1500);
		} finally {
			setLoadingModalShow(false);
		}
	};

	const importUsers = async (e) => {
		e.preventDefault();

		try {
			setLoadingModalShow(true);
			await importLimsUsers();
			setUsers(await getAllUsers());
			setAlert("Successfully Saved", "success", 1500);
		} catch (err) {
			setAlert("Error Saving Changes", "danger", 1500);
		} finally {
			setLoadingModalShow(false);
		}
	};

	return (
		<>
			<div className="pt-2 pb-2 border-top">
				<LoadingModal modalShow={loadingModalShow} />
				<Row>
					<Col className="col-2">
						<ModalButton
							button={
								<>
									<i className={`fas fa-plus`} />
									<span> New User</span>
								</>
							}
							title={"New User"}
							modalShow={modalShow}
							setModalShow={(bool) => setModalShow(bool)}
							onButtonClick={() => setModalShow(true)}
							body={
								<>
									<Form onSubmit={(e) => onSubmit(e)}>
										<Form.Group controlId="formUsername">
											<Form.Label>Username</Form.Label>
											<Form.Control
												required
												type="text"
												name="username"
												placeholder="Enter Username"
												value={formData.username}
												onChange={(e) => onChange(e)}
											/>
										</Form.Group>
										<Form.Group controlId="formFirstName">
											<Form.Label>First Name</Form.Label>
											<Form.Control
												required
												type="text"
												name="firstName"
												placeholder="Enter First Name"
												value={formData.firstName}
												onChange={(e) => onChange(e)}
											/>
										</Form.Group>
										<Form.Group controlId="formLastName">
											<Form.Label>Last Name</Form.Label>
											<Form.Control
												required
												type="text"
												name="lastName"
												placeholder="Enter Last Name"
												value={formData.lastName}
												onChange={(e) => onChange(e)}
											/>
										</Form.Group>
										<Form.Group controlId="formEmail">
											<Form.Label>Email</Form.Label>
											<Form.Control
												required
												type="text"
												name="email"
												placeholder="Enter Email"
												value={formData.email}
												onChange={(e) => onChange(e)}
											/>
										</Form.Group>
										<Form.Group controlId="formPassword">
											<Form.Label>Password</Form.Label>
											<Form.Control
												required
												type="password"
												name="password"
												placeholder="Enter Password"
												value={formData.password}
												onChange={(e) => onChange(e)}
											/>
										</Form.Group>
										<Form.Group controlId="formConfirmPassword">
											<Form.Label>
												Confirm Password
											</Form.Label>
											<Form.Control
												required
												type="password"
												name="confirmPassword"
												placeholder="Confirm Password"
												value={formData.confirmPassword}
												onChange={(e) => onChange(e)}
											/>
										</Form.Group>
										<Container>
											<Button
												variant="primary"
												type="submit"
											>
												{"Create"}
											</Button>
										</Container>
									</Form>
								</>
							}
						/>
					</Col>
					<Col className="col-3">
						<Button
							variant="primary"
							className="modal-button btn-sm"
							onClick={(e) => importUsers(e)}
						>
							<i className={`fas fa-circle-down`} />
							<span> Import LIMS Users</span>
						</Button>
					</Col>
				</Row>
			</div>
			<ul className="no-bullets p-0">
				{loading && <Spinner />}
				{!loading &&
					users.map((user) => {
						return (
							<NestedList
								setUsers={setUsers}
								setAlert={setAlert}
								setLoadingModalShow={setLoadingModalShow}
								user={user}
								roles={roles}
								roleActive={roleActive}
							/>
						);
					})}
			</ul>
		</>
	);
};

const NestedList = ({
	setUsers,
	setAlert,
	user,
	roles,
	roleActive,
	setLoadingModalShow,
}) => {
	const [displayContent, setDisplayContent] = useState(false);
	const [editModalShow, setEditModalShow] = useState(false);
	const [deleteModalShow, setDeleteModalShow] = useState(false);

	const onClick = (e) => {
		e.preventDefault();

		if (roleActive) {
			setDisplayContent(!displayContent);
		}
	};

	const onEditClick = (e, bool) => {
		e.preventDefault();
		e.stopPropagation();
		setEditModalShow(bool);
	};

	const onDeleteClick = (e, bool) => {
		e.preventDefault();
		e.stopPropagation();
		setDeleteModalShow(bool);
	};

	const onDeleteSubmit = async (e, id) => {
		e.preventDefault();
		setDeleteModalShow(false);
		setLoadingModalShow(true);

		try {
			await deleteUser(id);
			setUsers(await getAllUsers());
			setAlert("Successfully Saved", "success", 1500);
		} catch (err) {
			setAlert("Error Saving Changes", "danger", 1500);
		} finally {
			setLoadingModalShow(false);
		}
	};

	return (
		<li key={user.id}>
			<ClickableCard
				onClick={(e) => onClick(e)}
				body={
					<Row>
						<Col className="col-8">
							<span>{`${user.firstName} ${user.lastName}`}</span>
						</Col>
						<Col className="col-2">
							<ModalButton
								button={
									<>
										<span>Edit </span>
										<i className={`fas fa-edit`} />
									</>
								}
								title={"Edit"}
								body={
									<EditForm
										id={user.id}
										username={user.username}
										firstName={user.firstName}
										lastName={user.lastName}
										email={user.email}
										setEditModalShow={(bool) =>
											setEditModalShow(bool)
										}
										setLoadingModalShow={(bool) =>
											setLoadingModalShow(bool)
										}
										setUsers={setUsers}
										setAlert={setAlert}
									/>
								}
								modalShow={editModalShow}
								setModalShow={(bool) => setEditModalShow(bool)}
								onButtonClick={(e) => onEditClick(e, true)}
							/>
						</Col>
						<Col className="col-2">
							<ModalButton
								button={
									<>
										<span>Delete </span>
										<i className={`fas fa-trash`} />
									</>
								}
								variant={"danger"}
								title={`Delete user ${user.firstName} ${user.lastName}?`}
								body={
									<Row>
										<Col>
											<Button
												className="col-6"
												variant="danger"
												onClick={(e) =>
													onDeleteSubmit(e, user.id)
												}
											>
												{"Ok"}
											</Button>
										</Col>
										<Col>
											<Button
												className="col-6"
												variant="secondary"
												onClick={() =>
													setDeleteModalShow(false)
												}
											>
												{"Cancel"}
											</Button>
										</Col>
									</Row>
								}
								modalShow={deleteModalShow}
								setModalShow={(bool) =>
									setDeleteModalShow(bool)
								}
								onButtonClick={(e) => onDeleteClick(e, true)}
							/>
						</Col>
					</Row>
				}
			/>
			{displayContent && (
				<ElnTable
					setUsers={setUsers}
					setAlert={setAlert}
					user={user}
					list1={roles}
					list2={user.roles}
					setLoadingModalShow={setLoadingModalShow}
				/>
			)}
		</li>
	);
};

const EditForm = ({
	id,
	username,
	firstName,
	lastName,
	email,
	setEditModalShow,
	setLoadingModalShow,
	setUsers,
	setAlert,
}) => {
	const [formData, setFormData] = useState({
		id,
		username,
		firstName,
		lastName,
		email,
	});

	const onEditChange = (e) => {
		e.preventDefault();
		setFormData({ ...formData, [e.target.name]: e.target.value });
	};

	const onEditSubmit = async (e) => {
		e.preventDefault();
		setEditModalShow(false);
		setLoadingModalShow(true);

		try {
			const { id, username, firstName, lastName, email } = formData;
			await updateUser({ id, username, firstName, lastName, email });
			setUsers(await getAllUsers());
			setAlert("Successfully Saved", "success", 1500);
		} catch (err) {
			setAlert("Error Saving Changes", "danger", 1500);
		} finally {
			setLoadingModalShow(false);
		}
	};

	return (
		<Form onSubmit={(e) => onEditSubmit(e)}>
			<Form.Group controlId="formEditUsername">
				<Form.Label>Username</Form.Label>
				<Form.Control
					required
					type="text"
					name="username"
					placeholder="Enter Username"
					value={formData.username}
					onChange={(e) => onEditChange(e)}
				/>
			</Form.Group>
			<Form.Group controlId="formEditFirstName">
				<Form.Label>First Name</Form.Label>
				<Form.Control
					required
					type="text"
					name="firstName"
					placeholder="Enter First Name"
					value={formData.firstName}
					onChange={(e) => onEditChange(e)}
				/>
			</Form.Group>
			<Form.Group controlId="formEditLastName">
				<Form.Label>Last Name</Form.Label>
				<Form.Control
					required
					type="text"
					name="lastName"
					placeholder="Enter Last Name"
					value={formData.lastName}
					onChange={(e) => onEditChange(e)}
				/>
			</Form.Group>
			<Form.Group controlId="formEditEmail">
				<Form.Label>Email</Form.Label>
				<Form.Control
					required
					type="text"
					name="email"
					placeholder="Enter Email"
					value={formData.email}
					onChange={(e) => onEditChange(e)}
				/>
			</Form.Group>
			<Form.Group controlId="formEditPassword">
				<Form.Label>New Password</Form.Label>
				<Form.Control
					required
					type="password"
					name="password"
					placeholder="Enter Password"
					value={formData.password}
					onChange={(e) => onEditChange(e)}
				/>
			</Form.Group>
			<Form.Group controlId="formEditConfirmPassword">
				<Form.Label>Confirm New Password</Form.Label>
				<Form.Control
					required
					type="password"
					name="confirmPassword"
					placeholder="Confirm Password"
					value={formData.confirmPassword}
					onChange={(e) => onEditChange(e)}
				/>
			</Form.Group>
			<Container>
				<Button variant="primary" type="submit">
					{"Save"}
				</Button>
			</Container>
		</Form>
	);
};

const ElnTable = ({
	setUsers,
	setAlert,
	user,
	list1,
	list2,
	setLoadingModalShow,
}) => {
	const [assigned, setAssigned] = useState([{}]);
	const [unassigned, setUnassigned] = useState([{}]);

	useEffect(() => {
		let newList2 = list2.map((el) => el);
		const list2Data = list2.map((el) => el.name);
		let newList1 = list1.filter((el) => !list2Data.includes(el.name));

		for (let i = 0; i < newList1.length; i++) {
			newList1[i].checked = false;
		}

		for (let i = 0; i < newList2.length; i++) {
			newList2[i].checked = false;
		}

		setAssigned(newList2);
		setUnassigned(newList1);
	}, [list1, list2]);

	const handleSave = async (e) => {
		e.preventDefault();
		setLoadingModalShow(true);

		try {
			const userId = user.id;
			const roleIdArr = assigned.map((el) => el.id);
			await setUserRoles({ userId, roleIdArr });
			setUsers(await getAllUsers());
			await setAlert("Successfully Saved", "success", 1500);
		} catch (err) {
			await setAlert("Error Saving Changes", "danger", 1500);
		} finally {
			setLoadingModalShow(false);
		}
	};

	return (
		<AssignUnassignTable
			assigned={assigned}
			setAssigned={setAssigned}
			unassigned={unassigned}
			setUnassigned={setUnassigned}
			handleSave={handleSave}
			dataAttribute={"name"}
		/>
	);
};

const mapStateToProps = (state) => ({
	loading: state.projectType.loading,
	user: state.auth.user,
});

export default connect(mapStateToProps, { setAlert })(Users);

import React, { useState } from "react";
import { Button, Table, Col, Row } from "react-bootstrap"

const AssignUnassignTable = ({ assigned, setAssigned, unassigned, setUnassigned, handleSave, dataAttribute }) => {
    const [saveDisabled, setSaveDisabled] = useState(true);

    const handleAssign = (e) => {
        e.preventDefault();
        let newUnassigned = [];
        let newAssigned = assigned;

        for(let el in unassigned) {
            if(unassigned[el].checked) {
                unassigned[el].checked = false;
                newAssigned.push(unassigned[el]);
            } else {
                newUnassigned.push(unassigned[el]);
            }
        }

        setUnassigned(newUnassigned);
        setAssigned(newAssigned);
        setSaveDisabled(false);
    }

    const handleUnassign = (e) => {
        e.preventDefault();
        let newUnassigned = unassigned;
        let newAssigned = [];

        for(let el in assigned) {
            if(assigned[el].checked) {
                assigned[el].checked = false;
                newUnassigned.push(assigned[el]);
            } else {
                newAssigned.push(assigned[el]);    
            }
        }

        setUnassigned(newUnassigned);
        setAssigned(newAssigned);
        setSaveDisabled(false);
    }
    
    const handleAssignCheckboxClick = (e, index) => {
        const newAssigned = assigned.map(el => el);
        newAssigned[index].checked = !newAssigned[index].checked
        setAssigned(newAssigned);
    } 

    const handleUnassignCheckboxClick = (e, index) => {
        const newUnassigned = unassigned.map(el => el);
        newUnassigned[index].checked = !newUnassigned[index].checked
        setUnassigned(newUnassigned);
    } 

    return (
        <Row>
            <Col className="col-5 select-table">
                <Table>
                    <thead>
                        <tr>
                            <th>Unassigned</th>
                        </tr>
                    </thead>
                    <tbody>
                        { unassigned.map((el, index) => {
                            return (
                                <tr className={`checkbox-selector ${el.checked ? "selected" : ""}`} onClick={e => handleUnassignCheckboxClick(e, index)}>
                                    <td>
                                        <input type="checkbox" id={el[dataAttribute]} name={el[dataAttribute]} className="toggle-input" value={el.checked} checked={el.checked}/>
                                        <label for={el[dataAttribute]}>{el[dataAttribute]}</label>
                                    </td>
                                </tr>
                            )
                        }) }
                    </tbody>
                </Table>
            </Col>
            <Col className="col-">
                <Row className="center">
                    <Button variant="primary" className="modal-button btn-sm center" onClick={e => handleAssign(e)}>
                        <i className="fas fa-greater-than" />
                    </Button>
                </Row>
                <Row>
                    <Button variant="primary" className="modal-button btn-sm center" onClick={e => handleUnassign(e)}>
                        <i className="fas fa-less-than" />
                    </Button>
                </Row>
                <Row>
                    <Button variant="primary" className="modal-button btn-sm center" onClick={e => handleSave(e)} disabled={saveDisabled}>
                        save
                    </Button>
                </Row>
            </Col>
            <Col className="col-5 select-table">
                <Table>
                    <thead>
                        <tr>
                            <th>Assigned</th>
                        </tr>
                    </thead>
                    <tbody>
                        { assigned.map((el, index) => {
                            return (
                                <tr className={`checkbox-selector ${el.checked ? "selected" : ""}`} onClick={e => handleAssignCheckboxClick(e, index)}>
                                    <td>
                                        <input type="checkbox" id={el[dataAttribute]} name={el[dataAttribute]} className="toggle-input" value={el.checked} checked={el.checked}/>
                                        <label for={el[dataAttribute]}>{el[dataAttribute]}</label>
                                    </td>
                                </tr>
                            )
                        }) }
                    </tbody>
                </Table>
            </Col>
        </Row>
    )
}

export default AssignUnassignTable;
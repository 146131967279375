import React from "react";
import { Redirect } from "react-router-dom";
import { connect } from "react-redux";

const LimsLogin = ({ useOpenSocial }) => {
	const loginRedirect = () => {
		const search = window.location.search;
		const params = new URLSearchParams(search);
		const url = params.get("url");

		if (useOpenSocial === "true") {
			window.location = `${window._env_.REACT_APP_NODE_SERVER_HOSTNAME}:${window._env_.REACT_APP_NODE_SERVER_PORT}/api/opensocial/login?RelayState=${url}`;
		} else {
			return <Redirect to={url} />;
		}
	};

	return loginRedirect();
};

const mapStateToProps = (state) => ({
	useOpenSocial: state.config.useOpenSocial,
});

export default connect(mapStateToProps)(LimsLogin);

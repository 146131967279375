import React, { useState, useEffect, Fragment } from "react";
import { Link, Redirect } from "react-router-dom";
import { Form, Container, Button } from "react-bootstrap";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import axios from "../../constants/axiosRequest";
import LoadTemplateModal from "../layout/LoadTemplateModal";
import ModalButton from "../layout/ModalButton";
import Signature from "../layout/Signature";
import LoadingModal from "../layout/LoadingModal";
import { getProject, getProjects, publishNotebook, reviewNotebook, getNotebook, getTemplates, getLimsDocumentTypes, getLimsLocations, exportToLims, createLog } from "../../utils/apiCall";
import SideBar from "../layout/SideBar";
import { setAlert } from "../../actions/alert";
import { formatTimezone, getTimezone } from "../../utils/timezone";

const Notebook = ({ match, user, setAlert, limsModule }) => {
	const [experiment, setExperiment] = useState({});
	const [project, setProject] = useState({});
	const [projects, setProjects] = useState([]);
	const [loading, setLoading] = useState(true);
	const [publisher, setPublisher] = useState("");
	const [isPublished, setIsPublished] = useState(false);
	const [reviewer, setReviewer] = useState("");
	const [isReviewed, setIsReviewed] = useState(false);
	const [templates, setTemplates] = useState([]);
	const [updateEditor, setUpdateEditor] = useState(false);
	const [docEditor, setDocEditor] = useState("");
	const [tzOption, setTzOption] = useState("");
	const [loadingModalShow, setLoadingModalShow] = useState(false);
	const [exportModalShow, setExportModalShow] = useState(false);
	const [exportFormData, setExportFormData] = useState({
		documentTypeId: 0,
		locationId: 0,
	});
	const [documentTypes, setDocumentTypes] = useState([]);
	const [locations, setLocations] = useState([]);
	const { documentTypeId, locationId } = exportFormData;

	const url = `${window._env_.REACT_APP_NODE_SERVER_HOSTNAME}:${window._env_.REACT_APP_NODE_SERVER_PORT}`;

	useEffect(() => {
		const fetchData = async () => {
			const project = await getProject(match.params.projectId);
			setProject(project);

			if (!project) {
				return;
			}

			const externalId = project.externalId;
			const refinfoGroupId = project.refInfoGroupId;

			if (externalId && refinfoGroupId) {
				const limsDocumemntTypes = await getLimsDocumentTypes(user.externalId, limsModule);
				const limsLocations = await getLimsLocations(user.externalId, limsModule);
				setDocumentTypes(limsDocumemntTypes);
				setLocations(limsLocations);

				setExportFormData({
					documentTypeId: limsDocumemntTypes[0].DOCUMENTTYPEID,
					locationId: limsLocations[0].LocationID,
				});
			}

			const experiment = await getNotebook(match.params.projectId, match.params.experimentId);
			setExperiment(experiment);

			if (!experiment) {
				return;
			}

			const templates = await getTemplates();

			let projects = await getProjects();
			projects = projects.filter((project) => project.active);
			const projectExperiments = await axios.get(`${window._env_.REACT_APP_NODE_SERVER_HOSTNAME}:${window._env_.REACT_APP_NODE_SERVER_PORT}/api/search/experiments`);
			let typesList = [];

			for (let project of projects) {
				const filteredExperiments = projectExperiments.data.filter((experiment) => experiment.projectId === project.id);
				project.experiments = filteredExperiments;

				if (!typesList.includes(project.type)) {
					typesList.push(project.type);
				}
			}

			setProjects(projects);
			setIsPublished(experiment.isPublished);
			setPublisher(experiment.publisher);
			setIsReviewed(experiment.isReviewed);
			setReviewer(experiment.reviewer);
			setTemplates(templates);
			setLoading(false);

			if (docEditor) {
				docEditor.destroyEditor();
			}

			if (experiment.documentType !== "pdf") {
				const userName = user.FirstName + " " + user.LastName;
				const config = {
					info: {
						owner: experiment.createdBy,
					},
					document: {
						fileType: experiment.documentType,
						permissions: {
							edit: !experiment.isPublished,
							review: !experiment.isReviewed,
						},
						title: experiment.title,
						url: url + "/files/projects/" + project.id + "/" + experiment.id + "." + experiment.documentType,
					},
					editorConfig: {
						callbackUrl:
							url +
							"/track?fileName=" +
							experiment.id +
							"." +
							experiment.documentType +
							"&description=" +
							experiment.description +
							"&title=" +
							experiment.title +
							"&projectTitle=" +
							project.id +
							"&urlPath=" +
							window.location.href +
							"&userName=" +
							userName +
							"&token=" +
							localStorage.token +
							"&flag=" +
							0,
						customization: {
							// autosave: false,
							// forcesave: true,
							showReviewChanges: isReviewed,
						},
						user: { name: userName },
					},
				};

				setDocEditor(new window.DocsAPI.DocEditor("placeholder", config));
			}
		};

		fetchData();
	}, []);

	useEffect(() => {
		if (docEditor) {
			docEditor.destroyEditor();
		}

		if (!experiment || !project) {
			return;
		}

		if (experiment.documentType !== "pdf") {
			const userName = user.FirstName + " " + user.LastName;
			const config = {
				document: {
					fileType: experiment.documentType,
					permissions: {
						edit: !isPublished,
						review: !isReviewed,
					},
					title: experiment.title,
					url: url + "/files/projects/" + project.id + "/" + experiment.id + "." + experiment.documentType,
				},
				editorConfig: {
					callbackUrl:
						url +
						"/track?fileName=" +
						experiment.id +
						"." +
						experiment.documentType +
						"&description=" +
						experiment.description +
						"&title=" +
						experiment.title +
						"&projectTitle=" +
						project.id +
						"&urlPath=" +
						window.location.href +
						"&userName=" +
						userName +
						"&token=" +
						localStorage.token +
						"&flag=" +
						0,
					customization: {
						// autosave: false,
						// forcesave: true,
						showReviewChanges: isReviewed,
					},
					user: { name: userName },
				},
			};

			setDocEditor(new window.DocsAPI.DocEditor("placeholder", config));
		}
	}, [updateEditor]);

	useEffect(() => {
		setTzOption(getTimezone(tzOption));
	}, [tzOption]);

	if (!project) {
		return <Redirect to="/projects" />;
	}

	if (!experiment) {
		return <Redirect to={"/projects/" + match.params.projectId + "/experiments"} />;
	}

	const onReviewClick = async () => {
		const projectId = match.params.projectId;
		const experimentId = match.params.experimentId;
		const userName = user.FirstName + " " + user.LastName;
		const dateTime = new Date();

		if (publisher === userName) {
			throw Error("A different user is required for reviewing.");
		}

		if (window.confirm("Are you sure you want to review this notebook?")) {
			setReviewer(userName);
			setIsReviewed(true);
			reviewNotebook(true, userName, dateTime, projectId, experimentId);
			setUpdateEditor(!updateEditor);
		}
	};

	const onPublishClick = () => {
		const projectId = match.params.projectId;
		const experimentId = match.params.experimentId;
		const userName = user.FirstName + " " + user.LastName;
		const dateTime = new Date();

		if (window.confirm("Are you sure you want to publish this notebook?")) {
			setPublisher(userName);
			setIsPublished(true);
			publishNotebook(true, userName, dateTime, projectId, experimentId);
			setUpdateEditor(!updateEditor);
		}
	};

	const onExportChange = async (e) => {
		setExportFormData({ ...exportFormData, [e.target.name]: e.target.value });
	};

	const onExportSubmit = async (e) => {
		e.preventDefault();

		try {
			setExportModalShow(false);
			setLoadingModalShow(true);
			await exportToLims(project.id, experiment.id, documentTypeId, locationId);
			await createLog("Export Experiment to LIMS", `${user.FirstName} ${user.LastName} exported experiment ${experiment.title} to the LIMS`);
			await setAlert("Successfully Saved", "success", 1500);
		} catch (err) {
			await setAlert("Error Saving Changes", "danger", 1500);
		} finally {
			setLoadingModalShow(false);
		}
	};

	const publisherSignature = (
		<Fragment>
			Published by <strong>{publisher}</strong> on <strong>{formatTimezone(experiment.publishedAt ? experiment.publishedAt : new Date(), tzOption)}</strong>
		</Fragment>
	);

	const reviewerSignature = (
		<Fragment>
			Reviewed by <strong>{reviewer}</strong> on <strong>{formatTimezone(experiment.reviewedAt ? experiment.reviewedAt : new Date(), tzOption)}</strong>
		</Fragment>
	);

	const spinner = (
		<div className="d-flex justify-content-center">
			<div className="spinner-border m-5" role="status">
				<span className="sr-only">Loading...</span>
			</div>
		</div>
	);

	return (
		<Fragment>
			{loading && spinner}
			{!loading && (
				<div className="container-fluid white-background">
					<LoadingModal modalShow={loadingModalShow} />
					<div className="row">
						<div className="col-lg-2 hidden-md">
							<div className="side-bar">
								<SideBar projectList={[].concat.apply([], projects)} />
							</div>
						</div>
						<div className="col-12 col-lg-10 workspace">
							<div className="container-fluid">
								<nav className="navbar navbar-expand-xl navbar-light">
									<h3>{experiment.title}</h3>
									<button
										className="navbar-toggler"
										type="button"
										data-toggle="collapse"
										data-target="#navbarSupportedContent"
										aria-controls="navbarSupportedContent"
										aria-expanded="false"
										aria-label="Toggle navigation">
										<span className="navbar-toggler-icon"></span>
									</button>
									<div className="collapse navbar-collapse" id="navbarSupportedContent">
										<ul className="navbar-nav mr-auto ml-auto">
											<li className="nav-item mr-3">
												{experiment.documentType !== "pdf" && (
													<LoadTemplateModal
														handleEditor={() => setUpdateEditor(!updateEditor)}
														templates={templates}
														experiment={experiment}
														projectId={match.params.projectId}
														handleReload={() => setLoading(true)}
														disabled={isPublished}
													/>
												)}
											</li>
											<li className="nav-item">
												<ModalButton
													button={
														<>
															<i className={`fas fa-file-arrow-up`} />
															<span> Export to LIMS</span>
														</>
													}
													title={"Export to LIMS Associated Files"}
													modalShow={exportModalShow}
													setModalShow={(bool) => setExportModalShow(bool)}
													onButtonClick={() => setExportModalShow(true)}
													body={
														<>
															<Form onSubmit={(e) => onExportSubmit(e)}>
																<Form.Group controlId="formDocumentTypeId">
																	<Form.Label>Document Type</Form.Label>
																	<Form.Control as="select" name="documentTypeId" value={exportFormData.documentTypeId} onChange={(e) => onExportChange(e)}>
																		{documentTypes.map((documentType) => (
																			<option value={documentType.DOCUMENTTYPEID}>{documentType.DOCUMENTTYPENAME}</option>
																		))}
																	</Form.Control>
																</Form.Group>
																<Form.Group controlId="formLocation">
																	<Form.Label>Location</Form.Label>
																	<Form.Control as="select" name="locationId" value={exportFormData.locationId} onChange={(e) => onExportChange(e)}>
																		{locations.map((location) => (
																			<option value={location.LocationID}>{location.LocationDesc}</option>
																		))}
																	</Form.Control>
																</Form.Group>
																<Container>
																	<Button variant="primary" type="submit">
																		{"Export"}
																	</Button>
																</Container>
															</Form>
														</>
													}
												/>
											</li>
										</ul>
										<ul className="navbar-nav">
											<li className="nav-item mr-3">
												<Link to="/projects" className="link">
													<i className="fas fa-project-diagram" />
													<span> Projects</span>
												</Link>
											</li>
											<li className="nav-item mr-3">
												<Link to="/templates" className="link">
													<i className="fas fa-copy" />
													<span> Templates</span>
												</Link>
											</li>
											<li className="nav-item mr-3">
												<Link to="/search" className="link">
													<i className="fas fa-search" />
													<span> Search</span>
												</Link>
											</li>
											<li className="nav-item">
												<Link to="/logs" className="link">
													<i className="fas fa-list" />
													<span> Activity Log</span>
												</Link>
											</li>
										</ul>
									</div>
								</nav>
								<div className="row">
									<div className="col-12 p-0">
										<div className="editor-wrapper">
											<div id="placeholder"></div>
										</div>
									</div>
									{experiment.documentType !== "pdf" && (
										<div className="container-fluid">
											<div className="row signature-button-wrapper">
												<div className="col-3 col-lg-1 signature-button">
													<button
														className="btn btn-primary mt-1"
														onClick={() => onReviewClick()}
														disabled={!isPublished || isReviewed || publisher === user.FirstName + " " + user.LastName}>
														Review
													</button>
												</div>
												<div className="col-3 col-lg-1 signature-button">
													<button className="btn btn-primary mt-1" onClick={() => onPublishClick()} disabled={isPublished}>
														Publish
													</button>
												</div>
												<div className="col-3 col-lg-5 signature">{isPublished && <Signature signature={publisherSignature} />}</div>
												<div className="col-3 col-lg-5 signature">{isReviewed && <Signature signature={reviewerSignature} />}</div>
											</div>
										</div>
									)}
								</div>
							</div>
						</div>
					</div>
				</div>
			)}
		</Fragment>
	);
};

Notebook.propTypes = {
	user: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
	user: state.auth.user,
	tzOption: state.timezone.tzOption,
	limsModule: state.config.limsModule,
});

export default connect(mapStateToProps, { setAlert })(Notebook);

import React, { useState, useEffect, Fragment } from "react";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import SideBar from "../layout/SideBar";
import { getTemplate, getProjects } from "../../utils/apiCall";

const TemplateBuilder = ({ match, user }) => {
	const [template, setTemplate] = useState({});
	const [projects, setProjects] = useState([]);
	const [loading, setLoading] = useState(true);

	const url = `${window._env_.REACT_APP_NODE_SERVER_HOSTNAME}:${window._env_.REACT_APP_NODE_SERVER_PORT}`;

	useEffect(() => {
		const fetchData = async () => {
			const template = await getTemplate(match.params.templateId);
			let projects = await getProjects();

			setTemplate(template);
			setProjects(projects);
			setLoading(false);

			const userName = user.FirstName + " " + user.LastName;
			const config = {
				info: {
					owner: template.createdBy,
				},
				document: {
					fileType: template.documentType,
					title: template.title,
					url:
						url +
						"/files/templates/" +
						template.id +
						"." +
						template.documentType,
				},
				editorConfig: {
					callbackUrl:
						url +
						"/track?fileName=" +
						template.id +
						"." +
						template.documentType +
						"&description=" +
						template.description +
						"&title=" +
						template.title +
						"&urlPath=" +
						window.location.href +
						"&userName=" +
						userName +
						"&token=" +
						localStorage.token +
						"&flag=" +
						1,
					user: { name: userName },
				},
			};

			new window.DocsAPI.DocEditor("placeholder", config);
		};

		fetchData();
	}, [match, url, user]);

	const spinner = (
		<div className="d-flex justify-content-center">
			<div class="spinner-border m-5" role="status">
				<span class="sr-only">Loading...</span>
			</div>
		</div>
	);

	return (
		<Fragment>
			{loading && spinner}
			{!loading && (
				<div className="container-fluid white-background">
					<div className="row">
						<div className="col-lg-2 hidden-md">
							<div className="side-bar">
								<SideBar
									projectList={[].concat.apply([], projects)}
								/>
							</div>
						</div>
						<div className="col-12 col-lg-10 workspace">
							<div className="container-fluid">
								<nav className="navbar navbar-expand-xl navbar-light">
									<h3>{template.title}</h3>
									<button
										className="navbar-toggler"
										type="button"
										data-toggle="collapse"
										data-target="#navbarSupportedContent"
										aria-controls="navbarSupportedContent"
										aria-expanded="false"
										aria-label="Toggle navigation"
									>
										<span className="navbar-toggler-icon"></span>
									</button>
									<div
										className="collapse navbar-collapse"
										id="navbarSupportedContent"
									>
										<ul className="navbar-nav ml-auto">
											<li className="nav-item mr-3">
												<Link
													to="/projects"
													className="link"
												>
													<i className="fas fa-project-diagram" />
													<span> Projects</span>
												</Link>
											</li>
											<li className="nav-item mr-3">
												<Link
													to="/search"
													className="link"
												>
													<i className="fas fa-search" />
													<span> Search</span>
												</Link>
											</li>
											<li className="nav-item">
												<Link
													to="/logs"
													className="link"
												>
													<i className="fas fa-list" />
													<span> Activity Log</span>
												</Link>
											</li>
										</ul>
									</div>
								</nav>
								<div className="row">
									<div className="col-12 p-0">
										<div className="editor-wrapper">
											<div id="placeholder"></div>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			)}
		</Fragment>
	);
};

TemplateBuilder.propTypes = {
	user: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
	user: state.auth.user,
});

export default connect(mapStateToProps)(TemplateBuilder);
